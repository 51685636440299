import React, { useState, Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
// import { Link } from 'gatsby-plugin-intl-v6'
import ExpandableCard from '../components/docs/expandableCard'
import Header from '../components/header'
import Footer from '../components/footer'
import Seo from '../components/seo'
import H2 from '../customMdx/h2'
import H3 from '../customMdx/h3'
import H4 from '../customMdx/h4'
import H5 from '../customMdx/h5'
import H6 from '../customMdx/h6'
import Codeblock from '../customMdx/codeblock'
import { arrDate } from '../utils/createTime'
import { StaticImage } from 'gatsby-plugin-image'

const components = {
  Link,
  ExpandableCard,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  pre: Codeblock
} // Provide common components here

const AnnouncementPage = ({ data: { allMdx, allFile } }: any) => {
  const content = allMdx.edges[0].node
  const tableOfContents = content.tableOfContents.items
  const slug = allMdx.edges[0].node.slug
  // console.log('======', slug)
  const intl = useIntl()
  
  let urlTitle: any
  let twitterUrl: any
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    urlTitle = '/' + window.location.pathname.slice(4)
    twitterUrl = window.location.href
  }

  return (
    <>
      <Seo title={content.frontmatter.title} description={content.frontmatter.description} meta={[]} lang={content.frontmatter.language} />
      <div>
        <div className={'community-list'}>
          <Header />
          
          <div className={'container community-side'}>
            <p className={'time'}>{ arrDate(content.frontmatter.date, intl.locale) }</p>
            <h2>{ content.frontmatter.title }</h2>
            <div className={'author-box'}>
            <div className={'author'}>
              <StaticImage
                className={'img'}
                placeholder="blurred"
                alt="AB logo"
                src="../static/images/logo.png"
                />
                AB
            </div>
            <div className={'share'}>
                <a href={'https://twitter.com/intent/tweet?text=' + content.frontmatter.title + twitterUrl} target="_blank">
                <span className={'share-span'}>share</span>
                <StaticImage
                    className={'img'}
                    placeholder="blurred"
                    alt="AB logo"
                    src="../static/images/home/twitter.png"
                />
                </a>
            </div>
            </div>
            <div className={'side-list'}>
              <MDXProvider components={components}>
                <MDXRenderer>{content.body}</MDXRenderer>
              </MDXProvider>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  )
}
// tableOfContents(maxDepth: 2)
export const query = graphql`
  query ($slug: String!, $relativeDirectory: String!, $language: String!) {
    allMdx(filter: { slug: { eq: $slug }, frontmatter: { language: { eq: $language } } }) {
      edges {
        node {
          slug
          rawBody
          body
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            language
            author
            description
          }
          tableOfContents
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: $relativeDirectory } }) {
      edges {
        node {
          fields {
            gitLogLatestAuthorName
            gitLogLatestAuthorEmail
            gitLogLatestDate
          }
          relativeDirectory
        }
      }
    }
  }
`
export default AnnouncementPage
